































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { CarouselBreakpoint } from '@/app_code/CarouselBreakpoint';

import { Listing } from 'client-website-ts-library/types/Listing';

import Cycler from './Cycler.vue';
import CyclerItem from './CyclerItem.vue';
import ListingCard from '../ListingCard.vue';

@Component({
  components: {
    Cycler,
    CyclerItem,
    ListingCard,
  },
})
export default class ListingCardCycler extends Vue {
  public isMobile = window.innerWidth < 768;

  public autoAdvance = true;

  public hiddenCards = false;

  public initialSlide = 0;

  @Prop()
  public listings: Listing[] | null = null;

  @Prop({ default: '' })
  public readonly href!: string;

  @Prop({ default: '' })
  public readonly id!: string;

  public breakpoints: CarouselBreakpoint[] = [
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1.2,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1.25,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3.5,
      },
    },
  ];

  @Watch('listings')
  handleListingsChanged() {
    if (this.listings !== null) {
      this.autoAdvance = this.listings.length > 3;
      this.hiddenCards = this.listings.length <= 3;
      this.initialSlide = this.hiddenCards
        ? Math.floor(this.listings.length / 2)
        : 0;
    }
  }
}
