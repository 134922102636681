

































































import { Component, Mixins } from 'vue-property-decorator';

import { ListingFilter } from 'client-website-ts-library/filters';
import {
  Listing,
  ListingCategory,
  ListingStatus,
} from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';

import { View } from 'client-website-ts-library/plugins';
import ImageBox from '@/components/ImageBox.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import GridNavigation from '@/components/GridNavigation.vue';
import GridNavigationItem from '@/components/GridNavigationItem.vue';
import ListingCardCycler from '@/components/Cycler/ListingCardCycler.vue';
import TestimonialCycler from '@/components/Testimonials/TestimonialCycler.vue';
import LinkButton from '@/components/UI/LinkButton.vue';
import NavigationLink from '@/components/Common/NavigationLink.vue';

@Component({
  components: {
    ImageBox,
    VideoPlayer,
    GridNavigation,
    GridNavigationItem,
    ListingCardCycler,
    TestimonialCycler,
    LinkButton,
    NavigationLink,
  },
})
export default class Home extends Mixins(View) {
  public salesListings: Listing[] | null = null;

  public rentalListings: Listing[] | null = null;

  private listingSalesFilter: ListingFilter = new ListingFilter({
    Categories: [
      ListingCategory.ResidentialSale,
      ListingCategory.ResidentialLand,
      ListingCategory.Rural,
    ],
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    PageSize: 8,
  });

  private listingRentalsFilter: ListingFilter = new ListingFilter({
    Categories: [ListingCategory.ResidentialRental],
    Statuses: [ListingStatus.Current],
    PageSize: 8,
  });

  mounted() {
    API.Listings.Search(this.listingSalesFilter).then((listings) => {
      this.salesListings = listings.Items;
    });

    API.Listings.Search(this.listingRentalsFilter).then((listings) => {
      this.rentalListings = listings.Items;
    });
  }
}
